.App {
  text-align: center;
  background-color: black;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

h1 {
  line-height: 102%;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

footer {
  float: left;
  width: 100%;
}

.App-header {
  background-color: #282c34;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.zoomable-image-container {
  position: relative;
}

.zoomable-image-container:hover::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1;
}

.zoomable-image-title {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: white;
  font-size: 1.5rem;
  font-weight: bold;
  z-index: 2;
  opacity: 0;
  transition: opacity 0.6s ease-in-out;
}

.zoomable-image-container:hover .zoomable-image-title {
  opacity: 1;
}

.pre-line {
  white-space: pre-line;
}

#vision {
  padding-left: 4rem;
  padding-right: 4rem;
}

#mission a {
  background-color: #A100FF;
  color: white;
}

#mission button.active {
  background-color: transparent;
  color: purple;
  border: 1px solid #A100FF;
}


#mission {
  background-color: #000000;
}

#mission h3 {
  color: #A100FF;
  font-weight: 700;
  line-height: 1rem;
}

#mission .grid-cols-3 .col-span-1 {
  border: solid 1px #fff;
  font-size: 0.75rem;
}

#mission .grid-cols-1 .col-span-1 {
  border: solid 1px #fff;
  font-size: 0.75rem;
}

#elements {
  background-color: #000000;
  
  padding-left: 12rem;
  padding-right: 12rem;
}

#elements img {
  text-align: center;
  margin: auto;
  padding: 15%;;
}

#elements h3 {
  color: #A100FF;
  font-weight: 700;
  line-height: 1rem;
}

#elements .grid-cols-3 .col-span-1 {
  border: solid 1px #fff;
  font-size: 0.75rem;
}

#elements .grid-cols-1 .col-span-1 {
  border: solid 1px #fff;
  font-size: 0.75rem;
}

#elements .purple {
  border: solid 1px #A100FF !important;
  font-size: 0.75rem;
}

#createheader {
  color: white;
  background-image: url('./assets/images/Background_Bubbles.svg');
  padding-left: 15%;
  padding-right: 15%;
}

#subheadline {
  padding-left: 20%;
  padding-right: 20%;
}

#character {
  background-color: #000000;
}

#character img {
  text-align: center;
  margin: auto;
  padding: 15%;;
}

#character .main {
  text-align: center;
  margin: auto;
  padding: 0;
  max-width: 100%;
}

#character h3 {
  color: #A100FF;
  font-weight: 700;
  line-height: 1rem;
}

#character .grid-cols-3 .col-span-1 {
  border: solid 1px #fff;
  font-size: 0.75rem;
}

#character .grid-cols-1 .col-span-1 {
  border: solid 1px #fff;
  font-size: 0.75rem;
}

#character .purple {
  border: solid 1px #A100FF !important;
  font-size: 0.75rem;
}




#worlds {
  background-color: #000000;
  
}

#worlds img {
  text-align: center;
  margin: auto;
  padding: 15%;;
}

#worlds div img {
  text-align: center;
  margin: auto;
  padding: 0;
  max-width: 100%;
}

#worlds h3 {
  color: #A100FF;
  font-weight: 700;
  line-height: 1rem;
}

#worlds .grid-cols-3 .col-span-1 {
  border: solid 1px #fff;
  font-size: 0.75rem;
}

#worlds .grid-cols-1 .col-span-1 {
  border: solid 1px #fff;
  font-size: 0.75rem;
}

#worlds .purple {
  border: solid 1px #A100FF !important;
  font-size: 0.75rem; 
}


#banner {
  background-color: #000000;
    padding-left: 4rem;
    padding-right: 4rem;
}

#banner img {
  margin: auto;
  max-width: 100%;
  padding-left: 8rem;
  padding-right: 8rem;
}

#banner h3 {
  color: #A100FF;
  font-weight: 700;
  line-height: 1rem;
}

#banner .grid-cols-3 .col-span-1 {
  border: solid 1px #fff;
  font-size: 0.75rem;
}

#banner .grid-cols-1 .col-span-1 {
  border: solid 1px #fff;
  font-size: 0.75rem;
}

#banner .purple {
  border: solid 1px #A100FF !important;
  font-size: 0.75rem; 
}

#bannertwo img {
  margin: auto;
  max-width: 100%;
  padding-left: 8rem;
  padding-right: 8rem;
}

#bannerthree img {
  margin: auto;
  max-width: 100%;
  padding-left: 8rem;
  padding-right: 8rem;
}


#solution {
  background-image: url('./assets/images/ACC_NGGM_Gradients_080324.jpg');
}

.infinite {
  display: block;
  font-size: 3.5rem;
  line-height: 2rem;
  color: #A100FF;
  font-weight: 400;
  padding-bottom: 0.5rem;
}

.bg-purple-500 {
  background-color: #A100FF;
}

footer a:hover {
  color: #A100FF;
}

.footerSocialNetworks {
  position: relative;
  min-width: 200%;
}

.benefitColumn {
  border: solid 1px #fff;
}

/* Background override styles */

.carousel .slide .legend {
  display: grid;
  -webkit-transition: none !important;
  -moz-transition: none !important;
  -ms-transition: none !important;
  -o-transition: none !important;
  transition: none !important;
  position: absolute;
  bottom: 1rem !important;
  top: 1rem !important;
  left: 2rem !important;
  margin-left: 0 !important;
  width:33% !important;
  border-radius: 0px !important;
  background: #fff !important;
  color: #000 !important;
  padding: 1rem !important;
  margin: auto;
  text-align: left !important;
  align-items: start;
  opacity: 0.85 !important;
  -webkit-transition: 1 !important;
  -moz-transition: 1 !important;
  -ms-transition: 1 !important;
  -o-transition: 1 !important;
  transition: 1 !important;
}

.legend a {
  background-color: #A100FF;
  color: white;
  position: absolute;
}

.legend p {
  display: none;
}

.primaryFilters {
  max-width: 490px;
}

.legend a:hover {
  border: solid 2px #A100FF;
  background-color: white;
  color: #A100FF;
}

.carousel .slide .legend h2 {
  font-family: Graphik-Bold;
}

.carousel .slider-wrapper ul.animated {
  height: 26.5vw;
}

.carousel .thumb {
  border: 3px solid #000 !important;
}

.carousel .thumb img {
  transform: translateY(2vw) scale(1.7) !important;
}

.carousel .thumbs-wrapper {
  margin: 0 !important;
  margin-top: 1rem !important;
}

.carousel .thumb.selected, .carousel .thumb:hover {
  border: 3px solid #FFFFFF !important;
}

.carousel .thumb {
  width: 16.33% !important;
  padding: 0px !important;
}

.clickToPreview {
  width: 100%;
  display: inline-block;
  border-bottom: solid 1px #e3e3e3;
  font-size: xx-small;
}

@media (max-width: 767px) {
.grid-cols-3 > * {
  flex-basis: 100%;
  max-width: 100%;
  }

  #subheadline {
    padding-left: 0;
    padding-right: 0;
  }
}

@media (min-width: 1025px) {
  .legend p {
    display: block;
  }

  .legend a {
    bottom: 0.6rem;
  }
}

.storeHeaderImage {
  position: relative;
  top: -200px;
}

@media (max-width: 1024px) {
  .slider .slide img {
    max-height: 17rem;
  }

  .legendText {
    width: 200%;
  }

  .carousel .slider-wrapper {
    height: 370px;
  }

  .carousel .slide .legend {
    left: 0px !important;
    padding-top: 0px !important;
    top: 296px !important;
    color: white !important;
    background-color: #000000 !important;
  }

  .legend a {
    text-align: center;
    position: relative;
    width: 8rem;
    padding:0.3rem;
  }
}

@media (min-width: 1282px) {
  .carousel .slide .legend {
    width: 17.5vw !important;
  }
}

@media (min-width: 1282px) and (max-width: 1395px) {
  .legend a {
    padding-left: 1rem;
    padding-right: 1rem;
    padding-top: 0.2rem;
    padding-bottom: 0.2rem;
  }
}

@media (min-width: 532px) and (max-width: 715px) {
  .carousel .slide .legend {
    top: 42vw !important;
  }
}

@media (min-width: 360px) {
  .secondaryFilters button {
    margin-left: 0.3rem;
    margin-right: 0.3rem;
  }
}

@media (min-width: 715px) and (max-width: 1282px){
  .mssnColTwo {
    /*margin-right: 4rem;*/
    margin-left: 0px;
  }
  .mssnColOne {
    /*padding-left: 4rem;*/
  }
}

@media (min-width: 1536px) {
  .legend a {
    bottom: 1rem !important;
  }
}

@media (min-width: 1656px) {
  .carousel .slide .legend {
    top: 2rem !important;
    bottom: 2rem !important;
  }
}

@media (min-width: 715px) and (max-width: 788px) {
  .carousel .slider-wrapper {
    height: 368px !important;
  }
}

@media (min-width: 532px) and (max-width: 714px) {
  .carousel .slider-wrapper {
    height: 55vw !important;
  }
}

@media (max-width: 359px) {
  .secondaryFilters button {
    width: 100%;
  }
}

@media (max-width: 532px) {
  .carousel .slide .legend {
    top: 49vw !important;
  }

  .carousel .slider-wrapper {
    height: 68vw !important;
  }

  .carousel .slide .legend h2 {
    font-size: 1.2rem;
  }
}

@media (min-width: 1656px) {
  .carousel .slide .legend {
    top: 2rem !important;
    bottom: 2rem !important;
  }
}

@media (max-width: 360px) {
  .carousel .slider-wrapper {
    height: 81vw !important;
  }

  .infinite {
    font-size: 16vw !important;
  }
}

@media (max-width: 979px) {
  .logo {
    position: relative;
    top: -0.1vw;
  }
}
