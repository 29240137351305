@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: 'Graphik-Bold';
  src: url('./assets/fonts/Graphik-Bold.ttf') format('truetype');
}
@font-face {
  font-family: 'GT-Sectra-Fine_Regular';
  src: url('./assets/fonts/Graphik TTF 161229/GT_Sectra_Fine/GT-Sectra-Fine-Regular.otf')
    format('opentype');
}
@font-face {
  font-family: 'Graphik-Regular';
  src: url('./assets/fonts/Graphik-Regular.ttf') format('truetype');
}
body,
html {
  margin: 0;
  font-family: [ 'Graphik-Regular', sans-serif];
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
  width: 100%;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}
